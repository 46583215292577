<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center">
            <h2>Software development report (February – October 2020)</h2>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
            <div class="chart-wrapper">
                <canvas baseChart  style="height: 250px;"
                [height]="'250px'"
                [datasets]="pieAppsChartData"
                [labels]="pieAppsChartLabels"
                [options]="pieAppsChartOptions"
                [plugins]="pieAppsChartPlugins"
                [legend]="pieAppsChartLegend"
                [chartType]="pieAppsChartType">
              </canvas>
            </div>
            <h3 class="text-center">Applications running [total: 24]</h3>
        </div>

        <div class="col-4">            
            <div class="chart-wrapper">
                <canvas baseChart  style="height: 250px;"
                [height]="'250px'"
                [datasets]="pieCommitsChartData"
                [labels]="pieCommitsChartLabels"
                [options]="pieCommitsChartOptions"
                [plugins]="pieCommitsChartPlugins"
                [legend]="pieCommitsChartLegend"
                [chartType]="pieCommitsChartType">
              </canvas>
            </div>
            <h3 class="text-center">Applications commits [total: 1682]</h3>
        </div>
        <div class="col-4">
            <div class="chart-wrapper">
                <canvas baseChart  style="height: 250px;"
                [height]="'250px'"
                [datasets]="pieCodeLinesChartData"
                [labels]="pieCodeLinesChartLabels"
                [options]="pieCodeLinesChartOptions"
                [plugins]="pieCodeLinesChartPlugins"
                [legend]="pieCodeLinesChartLegend"
                [chartType]="pieCodeLinesChartType">
              </canvas>
            </div>
            <h3 class="text-center">Applications lines of code [total: 3085915]</h3>
        </div>
    </div>

    <div class="row mt-2" *ngFor="let project of projects">
        <div class="col-12">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-expansion-title>
                        <h3 class="text-left pt-3">{{project.name}}</h3>
                    </mat-expansion-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-4" *ngFor="let commitItem of project.commit">
                        <i class="fab fa-gitlab"></i>
                        <div class="bd-callout bd-callout-info">
                            <div class="text-center">{{commitItem.number}}</div>
                            <div class="text-center">{{commitItem.title}}</div>
                        </div>
                    </div>
                </div>

                <p class="text-justify" [innerHTML]="project.description">
                </p>

                <h4>Stack</h4>
                <ul>
                    <li class="list=group-item" *ngFor="let stackItem of project.stackList">{{stackItem}}</li>
                </ul>

                <!-- <img src="/assets/images/{{project.stackImage}}[0]" alt=""/> -->
                <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
                    <ng-template ngbSlide *ngFor="let imageItem of project.stackImages">
                        <img src="assets/images/{{imageItem.name}}" alt="Image" />

                        <div class="carousel-caption">
                            <h3>{{imageItem.title}}</h3>
                        </div>
                    </ng-template>
                </ngb-carousel>

                <div class="row">
                    <h3>Main Developments</h3>
                </div>
                <ul>
                    <li *ngFor="let mainDev of project.mainDevelopments" [innerHTML]="mainDev"></li>
                </ul>
            </mat-expansion-panel>
        </div>

        <hr />
    </div>
</div>