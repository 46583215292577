<div class="container-fluid mt-4">
    <div class="mb-2" *ngFor="let wpItem of worpackages">
        <div class="col-12">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-expansion-title>
                        <h3 class="text-left pt-3">{{wpItem.title}}</h3>
                    </mat-expansion-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-12">
                        <i class="fab fa-gitlab"></i>
                        <div class="bd-callout bd-callout-info">
                            <div class="text-center">
                                {{wpItem.hours}}
                            </div>

                            <div class="text-center">Hours spend</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <mat-list>
                            <mat-list-item *ngFor="let task of wpItem.wpList">
                                <div mat-line>{{task}}</div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>