import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-yearly-report',
  templateUrl: './yearly-report.component.html',
  styleUrls: ['./yearly-report.component.scss']
})
export class YearlyReportComponent implements OnInit {

  pieAppsChartOptions: ChartOptions = {
    responsive: true,
  };
  pieAppsChartLabels: Label[] = ['Frontend App', 'Service on Premise', 'Backend App', 'Databases', ];
  pieAppsChartType: ChartType = 'doughnut';
  pieAppsChartLegend = true;
  pieAppsChartPlugins = [];

  pieAppsChartData: ChartDataSets[] = [
    { data: [4, 6, 9, 5], label: 'Applications' }
  ];

  //Commits
  pieCommitsChartOptions: ChartOptions = {
    responsive: true,
  };
  pieCommitsChartLabels: Label[] = ['SCALE (Trucks platform)', 'SCALE WEB API (Trucks)', 'SCALE Log API (Trucks)', 'CRM', 'Contract manager and document converter','Company registration form', 'Data validation module (SCALE 2.0)', 'OAuth and LDAP authentication' ];
  pieCommitsChartType: ChartType = 'doughnut';
  pieCommitsChartLegend = true;
  pieCommitsChartPlugins = [];

  pieCommitsChartData: ChartDataSets[] = [
    { data: [261, 247, 12, 538, 145, 124, 145, 94], label: 'Commits' }
  ];

  //codeLines
   //Commits
   pieCodeLinesChartOptions: ChartOptions = {
    responsive: true,
  };
  pieCodeLinesChartLabels: Label[] = ['SCALE (Trucks platform)', 'SCALE WEB API (Trucks)', 'SCALE Log API (Trucks)', 'CRM', 'Contract manager and document converter','Company registration form','Data validation module (SCALE 2.0)', 'OAuth and LDAP authentication'];
  pieCodeLinesChartType: ChartType = 'doughnut';
  pieCodeLinesChartLegend = true;
  pieCodeLinesChartPlugins = [];

  pieCodeLinesChartData: ChartDataSets[] = [
    { data: [2799150, 31569, 10323, 75025, 4709, 62524, 124420, 40999 ], label: 'Commits' }
  ];

  public projects: any = [
    {
      name: 'SCALE (Trucks platform)',
      codeLines: 2799150,
      description: `It is a web application initially developed by Tec-Systems with the purpose of collecting data for the Trucks cartel case. This application stores assignors’ data information, company documents and other details for trucks, both inside and outside the cartel period.
      SCALE (trucks platform) enables CDC assignors to upload documents, enter trucks technical details, payment plans, trucks end of use and information about other business entities involved.`,
      stackList: [
        "Developed in .Net webforms (C#).",
        "MSSQL Database.",
        "Frontend integrated with the webforms Framework (cshtml)."],
      stackImages: [{
        title: "Trucks status overview.",
        name: "scale1.png"
      },
      {
        title: "Payment plans.",
        name: "scale2.png"
      
      },
      {
        title: "Trucks platform code.",
        name: "scale-stack.png"
      }],
      commit: [
        {
          title: "Commits backend",
          number: 261
        }],
      mainDevelopments: [
        "Payment plans and financial details modules.",
        "Transaction status overview for assignors.",
        "Additional classifications for assignors: <b>sleeping</b> assignor, <b>strike-out</b> assignor and <b>not claiming</b> assignor.",
        "SCALE migration and deployment with Windows docker containers under SMARTEC infrastructure.",
        "Database cleaning and schema optimization.",
        "General bug fixing and features enhancement."
      ],
      workpakages: []
    },
    {
      name: "SCALE WEB API (Trucks)",
      codeLines: 31569,
      description: `It is a web and server-based application used internally to bulk import trucks data, which refers to using Excel files to save data for more than one truck at the time. The file may include fast/full mode information, technical details, DICE data, payment plans, additional payments, as well as ends of use.
      <br><br>
    The module also allows to upload and download documents assigned to companies and trucks.`,
      stackList: [
        ".Net Core 3.1 backend (C#).",
        "MSSQL 2018 Database.",
        "Angular 10 GUI (HTML,CSS,TS)."],
      stackImages: [
        {
          title: "Web SCALE Dashboard.",
          name: "web1.png"
        
        },
        {
          title: "Resources manager.",
          name: "scale2.png"
        
        },
        {
          title: "Swagger API documentation.",
          name: "core-api.png"
        
        },
        {
        title: "SCALE WEB API code",
        name: "SCALE-core-api.png"
      }],
      commit: [
        {
          title: "Commits backend",
          number: 141
        },
        {
          title: "Commits frontend",
          number: 106
        }
      ],
      mainDevelopments: [
        'Complete migration from ASP .Net to .Net Core.',
        'Payment plans impot module.',
        'Extra payment import module.',
        'Multiple company in one file import. Subir varias empresas en un solo archivo de Excel.',
        'Column level data validation.',
        'Multiple document downloads by VIN.',
        'Document search and download functionality (pdf/zip).',
        'Improved resources (multilingual labels) editor.',
        'Multiple email endpoint to update assignors.',
        'Document Hash which among other allows for OCR .',
        'Payment plan generation.'
      ],
      workpakages: []
    },
    {
      name: "SCALE Log API (Trucks)",
      codeLines: 10323,
      description: `Server-side application designed to consolidate all the available logs for the trucks by VIN. The service collects imported data, assignor data entries, SILVER DAT information and transaction changes into one summary log classified by VIN, sorted by date and saved in a separate database.`,
      stackList: [
        "Developed in .Net webforms (C#)",
        "MongoDB database.",
      ],
      stackImages: [{
        title: "SCALE Log API code",
        name: "scale-log-api.png"
      }],
      commit: [
        {
          title: "Commits",
          number: 12
        }
      ],
      mainDevelopments: [
        "Complete service design and development.",
        "Find truck logs by VIN.",
        "Update Log summary with new data."
      ],
      workpakages: []
    },
    {
      name: "CRM",
      codeLines: "15113 + 56000 + 3912",
      description: `Fundamental client relation manager developed to centralize the client and cases interaction in one internal platform. The project stores company details all the required technical details, allows the contract generation and company/user management in a case and role level.
    This application has also grown into a more complete solution with modules used to edit and control multiple applications “labels”, document viewer, user administration, data validation module and potential case management hub.`,
      stackList: [
        "Developed in .Net webforms (C#)",
        "MongoDB database.",
        "Frontend in Angular 10."
      ],
      stackImages: [
        {
          title: "CRM dashboard",
          name: "crm1.png"
        },
        {
          title: "Resources manager.",
          name: "crm2.png"
        },
        {
          title: "Cases list.",
          name: "crm3.png"
        },
      {
        title: "CRM backend code",
        name: "crm-backend.png"
      }, {
        title: "CRM frontend code",
        name: "crm-frontend.png"
      }, {
        title: "Resources API code",
        name: "resources-API.png"
      }],
      commit: [
        {
          title: "Backend commits",
          number: 196
        },
        {
          title: "Frontend commits",
          number: 310
        },
        {
          title: "Resources API commits",
          number: 41
        }
      ],
      mainDevelopments: [
        "Complete backend/frontend implementation.",
        "Complete company registration with representatives, legal form, company excerpt, company address, company documents, projects assigned to the company.",
        "Custom address finder,",
        "Custom legal information.",
        "Project details administration.",
        "Mange internal (CDC) and non-internal users.",
        "Create and edit general multilingual labels.",
        "Create roles and permission for the different users.",
        "Send/store company contracts."
      ],
      workpakages: []
    },
    {
      name: "Contract manager and document converter",
      codeLines: "3620 + 89",
      description: `Service that stores contracts based on different document templates, such as the DoT, CPA or the NDA. The service automatically fills in the contract fills and generates a PDF file that can be downloaded or send to the assignor via email.`,
      stackList: [
        "Python (Flask).",
        ".Net Core 3.1 (C#).",
        "Node JS.",
        "MongoDB."
      ],
      stackImages: [{
        title: "Document converter code",
        name: "Document-converter.png"
      },
      {
        title: "Contract builder code",
        name: "Contract-Builder API.png"
      }],
      commit: [
        {
          title: "Contract manager commits",
          number: 130
        },
        {
          title: "Document converter commits",
          number: 15
        }
      ],
      mainDevelopments: [
        "Store and generate contract templates.",
        "Fill in contracts with company and representative’s data.",
        " Automatically convert Word contract to PDF.",
        "Attach QR code to contracts.",
        "Send contracts via email."
      ],
      workpakages: []
    },
    {
      name: "Company registration form",
      codeLines: 62524,
      description: `Standalone application to register companies in the CRM. It is a web user interface that can be embedded in a website or shared as a link (URI). The original developed form was modified and used for the Milk Spanish case.`,
      stackList: [
        "Angular 10 (HTML, CSS, TS)",
        "Google Maps API."
      ],
      stackImages: [
        {
          title: "Registration form natural person",
          name: "reg1.png"
        },
        {
          title: "Registration form address and other details.",
          name: "reg2.png"
        },
        {
        title: "Company registration frontend code",
        name: "Registration-form.png"
      }
      ],
      commit: [
        {
          title: "Commits",
          number: 124
        }
      ],
      mainDevelopments: [
        "Flexible web form development.",
        "Has double op-in option.",
        "Receives documents and company required data for the case.",
      ],
      workpakages: []
    },
    {
      name: "Data validation module (SCALE 2.0)",
      codeLines: " 16358 + 45446 +  812",
      description: `Based in the original SCALE concept and the experience acquired in the trucks case for the data import. This service has been designed as a multi module solution that uses both general and detailed validations written in team in Python, SAS and R by the Data and Economics team.
      Validations are group in different Excel files called “configurations”, in the interest of validate the user data before it is accepted and store in any of CDC’s internal system.`,
      stackList: [
        "Angular 10 (HTML, CSS, TS).",
        "Backernd .Net Core 3.1 (C#).",
        "Postgres SQL database.",
        "TypeScript."

      ],
      stackImages: [{
        title: "Data controller API code",
        name: "Data-Controller-api.png"
      }, {
        title: "Data validation API code",
        name: "Python-API.png"
      }, {
        title: "Data generation service code",
        name: "faker-api.png"
      }],
      commit: [
        {
          title: "Data collection API commits",
          number: 111
        },
        {
          title: "Pyhton API commits",
          number: 24
        },
        {
          title: "Data generatin API",
          number: 10
        }
      ],
      mainDevelopments: [
        "Create Python validation API.",
        "Develop Controller API to receive the excel file and coordinate the validations.",
        "Generate multilingual user report.",
        "GUI to create/edit/delete data validations and configurations.",
        "Log and version validation changes.",
        "Store import logs.",
        "Generate template file with basic toy data."
      ],
      workpakages: []
    },
    {
      name: "OAuth and LDAP authentication",
      codeLines: " 5605 + 35394",
      description: `Centralized authentication and authorization service for internal CDC’s users. The solution reuses the LDAP (Lightweight Directory Access Protocol) to manage and assign access to internal CDC solutions such as the CRM, OpenProject, Gitlab, etc.`,
      stackList: [
        "Angular 10 (HTML, CSS, TS)",
        "Node JS",
        "MongoDB"
      ],
      stackImages: [
        {
          title: "App registration.",
          name: "oauth.png"
  
        },  
        {
          title: "Login form",
          name: "auth1.png"
  
        },  
      {
        title: "OAuth backend code",
        name: "Oauth-backend.png"

      }, {
        title: "OAuth frontend code",
        name: "Oauth-frontend.png"
      }],
      commit: [
        {
          title: "Backend commits",
          number: 62
        },
        {
          title: "Frontend commits",
          number: 32
        }
      ],
      mainDevelopments: [
        "Microsoft Active Directory LDAP integration.",
        "Manage user internal application access.",
        "Role based authorization.",
        "Password hashing."
      ],
      workpakages: []
    },
    {
      name: "Milk case landing page",
      description: `WordPress website developed to present CDC’s offer for the Milk case. The framework allows multiple user edition and configuration for static web sites. Despite the fact this solution was made for one specific case, the technology and expertise gain from this implementation can be easily reused for other cases.`,
      stackList: [
        "PHP scripting.",
        "WordPress.",
        "MySQL database."
      ],
      stackImages: [{
        title:"Milk case landing page.",
        name: "leche1.png"

      }],
      commit: [
        {
          title: "Commits",
          number: "N/A"
        }
      ],
      mainDevelopments: [
        "Flexible web form development.",
        "Has double op-in option.",
        "Receives documents and company required data for the case.",
      ],
      workpakages: []
    },
    {
      name: "GitLab configuration code and container repository",
      description: `Gitlab is a web-based application that allows the company to store and version code and docker containers used internally and externally in the CDC group.`,
      stackList: [
        "Ruby.",
        "Bash.",
        "Docker."
      ],
      stackImages: [{
        title: "Gitlab project list",
        name: "gitlab1.png"
      },
      {
        title: "Gitlab commit history",
        name: "gitlab2.png"
      }
      ],
      commit: [
        {
          title: "Commits",
          number: "N/A"
        }
      ],
      mainDevelopments: [
        "On premise installation with docker hub.",
        "LDAP integration.",
        "CI/CD basic configuration with Jenkins.",
        "Migration to SMARTEC’s infrastructure."

      ],
      workpakages: []
    },
    {
      name: "Matomo Web Analytics",
      description: `Open source Web-based analytics system to measure all the incoming traffic and user interaction. Offers multiple user behaviour analysis and reports.`,
      stackList: [
        "MySQL.",
        "Bash.",
        "Docker."
      ],
      stackImages: [{
        title: 'Matomo Dashboard',
        name: 'matomo1.png'
      },
      {
        title: 'Matomo User Report',
        name: 'matomo2.png'
      }
      ],
      commit: [
        {
          title: "Commits",
          number: "N/A"
        }
      ],
      mainDevelopments: [
        "On premise installation with docker hub.",
        "LDAP integration.",
        "CI/CD basic configuration with Jenkins.",
        "Migration to SMARTEC’s infrastructure."

      ],
      workpakages: []
    },
    {
      name: "Docker and automated deployment",
      description: `Docker is a container solution that allows the company to store and operate different applications as individually wrapped solutions. This facilitates the services and project administration, increase the applications reliability, and improves the internal resources administration and possible migration.`,
      stackList: [
        "Ruby configurations.",
        "Jenkins.",
        "Git",
        "Dockercompose.",
        "Bash."
      ],
      stackImages: [{
        title: "Jenkins dashboard",
        name: "jenkins.png"
      }],
      commit: [
        {
          title: "Commits",
          number: "N/A"
        }
      ],
      mainDevelopments: [
        "SMARTEC infrastructure deployment.",
        "Create development, test, and production environment for each application.",
        "Integrate Docker with Jenkins and Gitlab, So the code is automatically built and deployed to the different internal machine.",
        "Independent files and storage configurations.",
        "Data scrapping database for ODAT, NORA and Sprint monitor."

      ],
      workpakages: []
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
