import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workpagelist',
  templateUrl: './workpagelist.component.html',
  styleUrls: ['./workpagelist.component.scss']
})
export class WorkpagelistComponent implements OnInit {
  constructor() { }

  public worpackages = [
    {
      title: 'SCALE (Trucks)',
      wpList: [
        'Task #2363: Problem fast mode icon',
        'Task #2414: Problem with Assignor 892 - cannot introduce "end of use" for some transactions',
        'Task #2185: Include a LimeSurvey link in SCALE',
        'Task #1012: Dev Trucks platform',
        'Task #2400: Error message when we click on the transaction date',
        'Task #2438: Not possible to introduce a transaction after having deleted a truck',
        'Task #2442: make imported net price visible for assignor',
        'Task #1483: we need new status "dormant assignor"',
        'Task #2310: integrate responses from "Activities of the company" survey ',
        'Task #2471: API | Issues with using multiple API services simultaneously ',
        'Task #2341: The down payment can be saved without a date (Payment plans)',
        'Task #2419: Data collection | Special process | Saint-Gobain (1. France; 2. United Kingdom)',
        'Task #2038: Data Collection | Special process | Carving Historical Assignors',
        'Task #2512: API | Migrate SCALE Web API From .Net Framework MVC to .Net Core 3.1',
        'Task #2538: Change limits for payment plans and price',
        'Task #2357: Payment plans: Strip date fields',
        'Task #2483: Check whether all changes on the trucks platform are actually logged',
        'Task #2439: Error message when creating a business entity directly via the payment plans module',
        'Task #2313: Remove Transport Fees from SCALE',
        'Task #2389: Add new dropdown listing for company form in the masterdata of an assignor',
        'Task #1166: Special Characters in proposed variables are shown as questionmarks',
        'Task #2587: Changes for buyout and residual value',
        'Task #2617: Include the Skip Module For Payment Plans',
        'Task #2610: Problem with excel file when there is a down payment',
        'Task #2627: translation ',
        'Task #2585: de-activate full mode for now to avoid confusion',
        'Task #2674: Data is not recorded on the payment plan module',
        'Task #2697: Import Data Request (monetisation)',
        'Task #2429: Import Type End of Use Date',
        'Task #2700: Hash Document Method',
        'Task #2701: Download Document',
        'Task #2703: SCALE API v2',
        'Task #2631: API | Hashing not working',
        'Task #2702: Documentation',
        'Task #2621: Transaction overview not working correctly',
        'Task #2721: JWT Auth Module',
        'Task #2212: API to send emails to assignors',
        'Task #2716: Payment plans | Autoselect that assignor payed for the truck at the end of leasing',
        'Task #2348: Payment Sum in Constant Amortization issue',
        'Task #2707: Values in excel file do not correspond with the values entered in the payment plans module',
        'Task #2704: different lines not reflected in total on excel sheets',
        'Task #2635: IN DE version of payment plan Excel file: put "Preis" instead of "Price"',
        'data_cleaning_task #1802: [13] Correct type_trucks values esp. for German "full mode" entries',
        'Task #2705: languages excel files',
        'Task #2867: SCALE API Token for Auth from SCALE Web forms',
        'Task #2868: Import Excel functionality migration',
        'Task #2869: Migrate get Resources and Configurations',
        'Task #2872: Updata Import GUI to Angular 10 and new dependencies',
        'Task #2714: Round power values',
        'Task #2934: General transaction revision for assignors (logs, transactions, icons)',
        'Task #2916: SCALE LIVE change log currently not helpful + runtime error',
        'Task #2923: Error loading fields for truck price form',
        'data_cleaning_task #1536: Check leasing transactions with implausible payment data',
        'Task #2605: Payment plan | Import module for Excel files',
        'Task #2940: Add new state for payment plans purchases',
        'Task #2932: DICE full mode import: move to new API',
        'Task #2946: Trucks platform complete migration to SMARTEC servers',
        'Task #2942: Rename payment details column and modify code',
        'Task #2939: error message when opening excel file from payment plans module',
        'Task #2952: LDAP based DB Access',
        'Task #2965: LDAP user list from the CRM',
        'Task #2949: Generate separate DB instances per  case',
        'Task #2988: Bug fix | resolve date formatting issues ',
        'Task #2948: Generate a test and production environment for all applications',
        'Task #2980: Create share file system',
        'Task #2950: Setup permanent share directories for static documents',
        'Task #2979: Implement Basic Auth',
        'data_cleaning_task #2667: [32] Clean "air_suspension" variable',
        'Task #3054: Controller API',
        'Task #2977: Log errors, request and responses',
        'Task #2978: Connect Flask API with SQL DB ',
        'Task #3053: Generate Configuration GUI',
        'Task #3055: Excel file upload to be validated',
        'Task #3052: Save validation GUI',
        'Task #3033: Transmission cleaning',
        'Task #3024: TecDetails partially not saved or not dispalyed ',
        'Task #3038: Assignor could not create an already existing VIN ',
        'Task #3056: Excel example file generation',
        'Task #2996: Payment plan | Import module Excel | reactivate import for several assignors (based on assignor_id)',
        'Task #2967: Complete .Net services migration',
        'Task #2995: Payment plan | Import module Excel | Extra payments',
        'Task #2982: Deploy as a docker container in test',
        'data_cleaning_task #3062: Cabin cleaning',
        'Task #2943: Dropdown Issue | SCALE displays first in dropdown when DB value unknown to system ',
        'Task #1825: Colour of fast-mode icon not always changing to green even when all required info is uploaded'
      ],
      hours: '650+'
    },
    {
      title: 'CRM',
      wpList: [
        'Task #2458: (Task 1) Create a form to capture the Project information',
        'Task #2457: Database Setup',
        'Task #2460: (Task 1) Implement localization and internationalization modules in the application, where the labels and application messages changes depending of the language selection',
        'Task #2455: Frontend Setup',
        'Task #2451: (Task 1) Create a form to capture the Assignor information. Requirements',
        'Task #2494: Planning/Code review',
        'Task #2477: Implement Nrwl/Nx for the Angular projects (Epic 6)',
        'Task #2465: User registration (Epic 5)',
        'Task #2462: (Task 3) Create an interface for internal user to translate and edit the labels in different languages',
        'Task #2461: (Task 2) Migrate resources (“labeled”) files to a database',
        'Task #2505: (Task 2) Add, Edit and Delete Country, City, Postal code',
        'Task #2601: Task 2. Frontend module for Roles, Modules & actions CRUD',
        'Task #2466: (Task 1) Create a registration form to capture the company details from an external user',
        'Task #2506: (Task 3) Registration Authority list',
        'Task #2456: Backend Setup',
        'Task #2504: (Task 1) Add, Edit and  Delete Countries',
        'Task #2598: Task 4. Setup Jenkins and Gitlab pipelines',
        'Task #2595: Task 1.Containerize the C# applications',
        'Task #2602: Task 3. Database changes',
        'Task #2594: Deployment (Epic 9)',
        'Task #2463: (Task 4) Export the labels back to the application',
        'Task #2503: Create Parametrization Module (Epic 7)',
        'Task #2720: Database Migration from MongoDB to Postgres',
        'Task #2597: Task 3. Containerize the Python file converter',
        'Task #2876: Restore/change password',
        'Task #2875: Control access and roles per case per user',
        'Task #2599: Roles, Actions and Authorization Module (Epic 10)'

      ],
      hours: '380+'
    },
    {
      title: 'Milk Case',
      wpList: [
        'Task #2793: Upload and save documents per Company',
        'Task #2733: Registration form tests',
        'Task #2791: Convert the contract to PDF',
        'Task #2806: Implement Gitlab-Jenkisn Webhooks',
        'Task #2781: Add Notary Details',
        'Task #2784: Remove Representatives for natural Person',
        'Task #2805: Generate Docker Compose',
        'Task #2795: Layout General Changes',
        'Task #2794: Validation Error Summary',
        'Task #2796: API modifications for the CRM',
        'Task #2865: Create Leche Information email',
        'Task #2798: Loading Screen Implementation',
        'Task #2801: Setup Jenkin Pipelines',
        'Task #2820: Milk Case Landing Page',
        'Task #2797: Database changes for new input fields',
        'Task #2804: Configure Proxy access',
        'Task #2821: Text Corrections',
        'Task #2802: Migrate the Mongo Database',
        'Task #2790: Upload 4 different contract formats',
        'Task #2822: Gallego Translation',
        'Task #2789: Generate QR code for contacts',
        'Task #2788: Upload DNI or NIF copy',
        'Task #2787: Add Nationality and DNI field',
        'Task #2825: FAQ Page',
        'Task #2786: Google API integration',
        'Task #2785: Add milk sold estimate per year',
        'Task #2783: Translation by default in ES',
        'Task #2782: Divide the Registration for Natural and Legal Person'
      ],
      hours: '190+'
    },
    {
      title: 'Admin tasks',
      wpList: [
        'Task #277: Internal organisation and planning (incl. status calls)',
        'Task #895: Higher level IT Management / Strategy',
        'Task #1672: Git and GitLab integration',
        'Task #2630: Create Confluence documentation for IT',
        'Task #1134: General developing infrastructure at CDC',
        'Task #100: Preparing conference calls',
        'Task #2864: Angular 10  Update',
        'Task #211: Personal time tracking',
        'Task #3048: Openproject Database migration',
        'Task #3050: Deploy under SMARTEC infrastructure',
        'Task #3049: Migrate documents and assets',
        'Task #3051: Upgrade OP to the latest version',
        'Task #3047: Openproject Upgrade'
      ],
      hours: '180+'
    }
  ];

  ngOnInit(): void {
  }
}
