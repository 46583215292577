<mat-toolbar>
  <span class="logo-text mr-3">SMARTEC Report</span>

  <button mat-button [matMenuTriggerFor]="yearMenu">Year</button>
  <mat-menu #yearMenu="matMenu">
    <button mat-menu-item>
      2020
    </button>
    <button mat-menu-item>2021</button>
  </mat-menu>
</mat-toolbar>

<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Applications">
    <app-yearly-report></app-yearly-report>
  </mat-tab>
  <mat-tab label="Workpackages">
    <app-workpagelist></app-workpagelist>
  </mat-tab>
</mat-tab-group>


<!-- <button mat-buttpn [matMenuTriggerFor]="subMenu"> 2020</button>
      <mat-menu #subMenu="yearMenu">
        <button mat-menu-item>Applications</button>
      </mat-menu> -->